@import '~nprogress/nprogress.css';

#nprogress .bar {
  background: var(--secondary);
}

/* Fancy blur effect */
#nprogress .peg {
  box-shadow: 0 0 10px var(--secondary), 0 0 5px var(--secondary);
}

#nprogress .spinner,
#nprogress .spinner-icon {
  display: none;
}
