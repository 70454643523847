// CoreUI Icons Set
$coreui-icons-font-path: '~@coreui/icons/fonts' !default;
@import '~@coreui/icons/scss/free.scss';

// Font Awesome Icons Set
// @import '~font-awesome/css/font-awesome.min.css';
$fa-font-path: '~@fortawesome/fontawesome-free/webfonts' !default;
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/brands';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';

@import-normalize;
@import 'variables';
@import 'bootstrap4-variables';
@import 'extends';
@import 'mixins';

// CoreUI styles
@import '~@coreui/coreui/scss/coreui';

@import './vendors/react-toastify';
@import './vendors/nprogress';

@import 'utils';
@import 'custom';

// Some temp fixes
@import 'fixes';
