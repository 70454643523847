@use "sass:color";
@import './variables';
// Here you can add other styles
img {
  max-width: 100%;
}

pre:last-child,
p:last-child,
label:last-child {
  margin-bottom: 0;
}

// fix CSS rules in CoreUI
.main {
  .container-fluid {
    padding-right: 15px;
    padding-left: 15px;
  }
}

.sidebar-minimized .sidebar,
.sidebar {
  $text-color: color.scale($primary-color, $saturation: 100%, $lightness: 30%);

  .nav-dropdown .nav-link {
    &:hover {
      color: $text-color;
      background-color: unset;

      .nav-icon {
        color: $text-color;
      }
    }

    &.active {
      color: unset;

      &:hover {
        color: unset;
        background-color: $primary-color;

        .nav-icon {
          color: unset;
        }
      }
    }
  }

  .nav-link {
    &:hover {
      color: $text-color;
      background-color: unset;

      .nav-icon {
        color: $text-color;
      }
    }

    &.active {
      color: #fff;
      background-color: $primary-color;

      .nav-icon {
        color: #fff;
      }

      &:hover {
        color: unset;
        background-color: $primary-color;

        .nav-icon {
          color: unset;
        }
      }
    }
  }
}

@media (min-width: $mobile) {
}

@media (min-width: $tablet) {
  // fix CSS rules in CoreUI
  .main {
    > .container-fluid {
      padding-right: 30px;
      padding-left: 30px;
    }
  }
}

@media (min-width: $desktop) {
}

@media (min-width: $lg-desktop) {
}
